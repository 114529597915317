import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../pages/home/index.vue'
import Mine from '../pages/mine/index.vue'
import Choose from '../pages/choose/index.vue'
import List from '../pages/choose/list.vue'
import Profile from '../pages/choose/profile.vue'
import Video from '../pages/video/index.vue'
import Game from '../pages/game/index.vue'
import Login from '../pages/login/index.vue'
import Register from '../pages/login/register.vue'
import ServiceOnline from '../pages/mine/ServiceOnline.vue'
import ServicePage from '../pages/mine/ServicePage.vue'
import Setting from '../pages/mine/Setting.vue'
import Infomation from '../pages/mine/Infomation.vue'
import Setname from '../pages/mine/Setname.vue'
import Setsex from '../pages/mine/Setsex.vue'
import SetPayPassword from '../pages/mine/SetPayPassword.vue'
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'
import Lottery from '../pages/lottery/index.vue'
import Notice from '../pages/mine/Notice.vue'
import PlayVideo  from '../pages/video/PlayVideo'
import Setbank  from '../pages/mine/Setbank'
import BindCard  from '../pages/mine/BindCard'
import Recharge from '../pages/mine/Recharge'
import Withdraw  from '../pages/mine/Withdraw'
import Personalreport  from '../pages/mine/Personalreport'
import GameRecord  from '../pages/mine/GameRecord'
import WithdrawRecord  from '../pages/mine/WithdrawRecord'
import RechargeRecord  from '../pages/mine/RechargeRecord'


Vue.use(VueRouter)
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:'Trang chủ'}},
    {path:'/Home',name:'home',component:Home,meta:{title:'Trang chủ'}},
	{path:'/Choose',name:'choose',component:Choose,meta:{title:'Thiên Địa Mỹ Nhân'}},
	{path:'/List',name:'list',component:List,meta:{title:'Danh sách'}},
	{path:'/Profile',name:'profile',component:Profile,meta:{title:'Trang cá nhân'}},
    {path:'/Mine',name:'mine',component:Mine,meta:{title:'Hồ sơ'}},
    {path:'/Video',name:'video',component:Video,meta:{title:'Rạp chiếu phim'}},
    {path:'/Game',name:'game',component:Game,meta:{title:'Ngành nghề'}},
    {path:'/Login',name:'login',component:Login,meta:{title:'Đăng nhập'}},
    {path:'/Register',name:'register',component:Register,meta:{title:'Đăng ký'}},
    {path:'/ServiceOnline',name:'ServiceOnline',component:ServiceOnline,meta:{title:'Hỗ trợ trực tuyến'}},
    {path:'/ServicePage',name:'ServicePage',component:ServicePage,meta:{title:'Hỗ trợ trực tuyến'}},
    {path:'/Setting',name:'Setting',component:Setting,meta:{title:'Cài đặt'}},
    {path:'/Infomation',name:'Infomation',component:Infomation,meta:{title:'Thông Tin Cá Nhân'}},
    {path:'/Setname',name:'Setname',component:Setname,meta:{title:'Sửa tên'}},
    {path:'/Setsex',name:'Setsex',component:Setsex,meta:{title:'Sửa giới tính'}},
    {path:'/SetPayPassword',name:'SetPayPassword',component:SetPayPassword,meta:{title:'Sửa mật khẩu rút tiền'}},
    {path:'/SetLoginPassword',name:'SetLoginPassword',component:SetLoginPassword,meta:{title:'Đổi mật khẩu'}},
    {path:'/Lottery',name:'Lottery',component:Lottery,meta:{title:'Bình chọn'}},
    {path:'/Notice',name:'Notice',component:Notice,meta:{title:'Thông báo'}},
    {path:'/PlayVideo',name:'PlayVideo',component:PlayVideo,meta:{title:'Xem video'}},
    {path:'/Setbank',name:'Setbank',component:Setbank,meta:{title:'Cài đặt ngân hàng'}},
    {path:'/BindCard',name:'BindCard',component:BindCard,meta:{title:'Cài đặt ngân hàng'}},
    {path:'/Withdraw',name:'Withdraw',component:Withdraw,meta:{title:'Rút tiền'}},
    {path:'/Recharge',name:'Recharge',component:Recharge,meta:{title:'Nạp tiền'}},
    {path:'/Personalreport',name:'Personalreport',component:Personalreport,meta:{title:'Chi tiết tài khoản'}},
    {path:'/WithdrawRecord',name:'WithdrawRecord',component:WithdrawRecord,meta:{title:'Lịch sử rút tiền'}},
    {path:'/RechargeRecord',name:'RechargeRecord',component:RechargeRecord,meta:{title:'Lịch sử nạp tiền'}},
    {path:'/GameRecord',name:'GameRecord',component:GameRecord,meta:{title:'Lịch sử bình chọn'}},
];


const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         
    document.title = to.matched[0].meta.title
    next()
})

export default router