<template>
	<div class="movie-hall page">
		<section id="team" class="team mowpad relative">
			<div class="title"><h1>Nghiêp Đoàn Lao Động</h1></div>
			<div class="mowtainer">
				<div class="mowgrid">
					<div class="member">
						<div class="image"><img src="/img/team/b1.jpg" alt="" /></div>
					</div>
					<div class="member">
						<div class="image"><img src="/img/team/b2.jpg" alt="" /></div>
					</div>
					<div class="member">
						<div class="image"><img src="/img/team/b3.jpg" alt="" /></div>
					</div>
					<div class="member">
						<div class="image"><img src="/img/team/b4.jpg" alt="" /></div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {Toast} from "vant";
export default {
	data() {
		return {
			active: 0,
			isLoading: false,
			count: 0,
			loading: false,
			finished: false,
			refreshing: false,
			videolitem: [],
			videolist: [],
			number: 0,
			page: 0,
			videoSwiperOption: {
				slidesPerView: "auto",
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
		};
	},
	methods: {
		getVideoClass() {
			this.$http({
				method: "get",
				url: "video_class",
			}).then(res => {
				this.videolitem = res.data;
			});
		},
		toPlayVideo(id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/PlayVideo?id=" + id});
			}
		},
		itemChange() {
			this.active = this.$refs.swiper.swiper.activeIndex;
			this.OnChange();
		},
		getVideoList() {
			this.$http({
				method: "get",
				data: {id: this.active, page: this.page},
				url: "video_list",
			}).then(res => {
				this.videolist = this.videolist.concat(res.data.data);
				this.count = res.data.count;
				this.page++;
			});
		},
		onLoad() {
			this.getVideoList();
			let timer = setTimeout(() => {
				if (this.refreshing) {
					this.videolist = [];
					this.refreshing = false;
				}
				this.loading = false;
				if (this.videolist.length === this.count) {
					this.finished = true;
				}
				this.finished && clearTimeout(timer);
			}, 500);
		},
		OnChange() {
			this.videolist = [];
			this.number = 0;
			this.page = 1;
			this.count = 0;
			this.getVideoList();
		},
		onRefresh() {
			setTimeout(() => {
				this.finished = false;
				this.loading = true;
				this.onLoad();
				this.isLoading = false;
				Toast("Làm mới thành công");
			}, 500);
		},
	},
	created() {
		this.getVideoClass();
		this.OnChange();
	},
};
</script>

<style lang="less" scoped>
.page {
	background-color: #f2f2f5;
}
.team {
    background: #9fdfe9;
    background-image: url(/img/team/2.webp);
    background-size: cover;
    z-index: 1;
    padding: 30px 0;
    min-height: 100vh;
}
#team {
    font-family: Inter, sans-serif;
    font-weight: 400;
    color: #fff !important;
}
.title {
    text-align: center;
    margin-bottom: 30px;
}
.title h1 {
    font-family: Old Fenris, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-shadow: 0 6px 8px #000;
    font-size: 38px;
    margin: 0 0 30px;
}
.mowtainer {
    width: 90%;
    margin: 0 auto;
}
.team .mowgrid {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-gap: 40px 30px;
    display: grid;
}
.team .member {
    position: relative;
    text-align: center;
    cursor: pointer;
}
.team .member .image {
    border-radius: 50%;
    border: 6px ridge #bca05c;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 15px #000;
}
.team .member img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1.15;
}
.team .member .image:after {
    position: absolute;
    content: "";
    background: url(/img/team/1.webp) 50%;
    height: 50px;
    width: 50px;
    bottom: -25px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    transition: all .2s;
}
.team .member:hover .image:after {
    bottom: -10PX;
}
</style>
