<template>
	<div class="convention-hall page">
		<div class="nav-bar van-nav-bar van-hairline--bottom">
			<div class="van-nav-bar__content"><div class="van-nav-bar__title van-ellipsis">Nước ngoài</div></div>
		</div>
		<div class="container">
			<div class="row">
				<div style="padding:10px">
					<div class="pageone">
						<div class="pageone-title" style="margin-bottom: -10px;">
							<h2><a href="javascript:void(0);" title="" style="font-size: 15px; margin-bottom: -10px;color:#333">Tuyển Dụng</a></h2>
							<p class="line-do"></p>
						</div>
						<div class="pageone-tab">
							<ul role="tablist" class="nav nav-tabs">
								<li class="active" style="background: rgb(245, 130, 32); height: 32px;"><a href="javascript:void(0);" role="tab" data-toggle="tab" style="color:#ff0">Tất cả đơn hàng mới</a></li>
								<li><a href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px;">Kỹ sư đi Nhật Bản</a></li>
								<li><a href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px;">Đơn hàng cho nam</a></li>
								<li><a href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px;">Đơn hàng cho nữ</a></li>
							</ul>
							<div id="style-2" class="tab-content">
								<div id="thong-so-ki-thuat" class="tab-pane active">
									<table class="donhang-home">
										<tbody>
											<tr>
												<td>Ngành nghề</td>
												<td>Ngày thi</td>
												<td>Giới tính</td>
												<td>Lương cơ bản</td>
												<td>Số lượng</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="[Phí tổng 105tr] Đơn hàng chế biến thực phẩm cho nữ đi Nhật Bản">Thực phẩm</a></td>
												<td>18/11/2023</td>
												<td>Nữ</td>
												<td>153.054 Yên</td>
												<td>30</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng Tokutei thực phẩm đầu Việt làm sủi cảo tỉnh Gunma">Thực phẩm</a></td>
												<td>13/11/2023</td>
												<td>Nam/Nữ</td>
												<td>170.000 Yên</td>
												<td>24</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD">Công xưởng</a></td>
												<td>23/10/2023</td>
												<td>Nam Nữ</td>
												<td>4000 AUD</td>
												<td>100</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng chế biến thực phẩm tại Nhật Bản tuyển 18 nữ">Thực phẩm</a></td>
												<td>26/12/2023</td>
												<td>Nữ</td>
												<td>176.000 Yên</td>
												<td>18</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Tuyển 30 nữ làm đóng gói công nghiệp tại Nhật Bản làm ở tỉnh Tokyo">Đóng gói</a></td>
												<td>22/11/2023</td>
												<td>Nữ</td>
												<td>157.000 Yên</td>
												<td>30</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng truyền thống tuyển 100 nữ và 20 nam làm chế biến thủy sản Nhật Bản">Thủy Sản</a></td>
												<td>16/12/2023</td>
												<td>Nam Nữ</td>
												<td>157.000 Yên</td>
												<td>120</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng dập kim loại linh kiện ô tô Nissan Nhật Bản tuyển 54 nam">Cơ khí</a></td>
												<td>11/11/2023</td>
												<td>Nam</td>
												<td>160.768 Yên</td>
												<td>54</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng chế biến đồ ăn sẵn tại Nhật Bản tuyển 60 nữ làm ở siêu thị">Thực phẩm</a></td>
												<td>15/10/2023</td>
												<td>Nữ</td>
												<td>157.000 Yên</td>
												<td>60</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng chế biến thực phẩm tại Kyoto Nhật Bản tuyển 54 nữ">Thực phẩm</a></td>
												<td>23/12/2023</td>
												<td>Nữ</td>
												<td>157.000 Yên</td>
												<td>54</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng công xưởng Nhật Bản tuyển 20 nam tại tỉnh Osaka">Phân Loại</a></td>
												<td>29/08/2023</td>
												<td>Nam</td>
												<td>167.385 Yên</td>
												<td>20</td>
											</tr>
										</tbody>
									</table>
									<div class="xemthemhome" style="margin-bottom:20px"><a href="javascript:void(0);" title="Xem thêm">Xem thêm</a></div>
								</div>
							</div>
						</div>
					</div>
					<div class="widget widget-highlight-job pagetow">
						<h2 class="widget-title">
							<a href="javascript:void(0);" title="Việc làm ngoài nước mới nhất" style="color:#fff">Việc làm ngoài nước mới nhất <font style="float: right; color: red; text-transform: none; font-size: 13px; margin-right: 10px; text-decoration: underline;">Xem thêm</font></a>
						</h2>
						<div class="clearfix"></div>
						<div class="widget-wrap scrollbar">
							<div class="widget-content">
								<ul>
									<li>
										<a href="javascript:void(0);" title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD"
											><h3>Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD</h3>
											<h4><span>Mức lương:</span>4000 AUD</h4>
											<h4><span>Quốc gia:</span>Úc</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="41 đơn hàng Singapore mới nhất tuyển liên tục"
											><h3>41 đơn hàng Singapore mới nhất tuyển liên tục</h3>
											<h4><span>Mức lương:</span>40000</h4>
											<h4><span>Quốc gia:</span>Singapore</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts"
											><h3>Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts</h3>
											<h4><span>Mức lương:</span>25 AUD/giờ</h4>
											<h4><span>Quốc gia:</span>Úc</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói"
											><h3>Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói</h3>
											<h4><span>Mức lương:</span>1.100 USD</h4>
											<h4><span>Quốc gia:</span>Ba lan</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm"
											><h3>Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm</h3>
											<h4><span>Mức lương:</span>1.100 USD</h4>
											<h4><span>Quốc gia:</span>Ba lan</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Thông báo chương trình xuất khẩu lao động Đức được nợ phí"
											><h3>Thông báo chương trình xuất khẩu lao động Đức được nợ phí</h3>
											<h4><span>Mức lương:</span>80 triệu</h4>
											<h4><span>Quốc gia:</span>CHLB Đức</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu"
											><h3>Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu</h3>
											<h4><span>Mức lương:</span>2,7 triệu Won</h4>
											<h4><span>Quốc gia:</span>Hàn Quốc</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông"
											><h3>Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông</h3>
											<h4><span>Mức lương:</span>3000 SGD</h4>
											<h4><span>Quốc gia:</span>Singapore</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Thông báo tuyển lao động đi làm việc tại CHLB Đức"
											><h3>Thông báo tuyển lao động đi làm việc tại CHLB Đức</h3>
											<h4><span>Mức lương:</span>2400 Eur</h4>
											<h4><span>Quốc gia:</span>Đức</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng"
											><h3>Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng</h3>
											<h4><span>Mức lương:</span>36 Triệu đồng</h4>
											<h4><span>Quốc gia:</span>Philippines</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp"
											><h3>Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp</h3>
											<h4><span>Mức lương:</span>85 triệu</h4>
											<h4><span>Quốc gia:</span>Australia Úc</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm"
											><h3>Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm</h3>
											<h4><span>Mức lương:</span>2.800 Euro</h4>
											<h4><span>Quốc gia:</span>CHLB Đức</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng"
											><h3>Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng</h3>
											<h4><span>Mức lương:</span>80 triệu</h4>
											<h4><span>Quốc gia:</span>New Zealand</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển lao động làm xây dựng tại New Zealand"
											><h3>Tuyển lao động làm xây dựng tại New Zealand</h3>
											<h4><span>Mức lương:</span>4000- 6,500 NZD</h4>
											<h4><span>Quốc gia:</span>New Zealand</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand"
											><h3>Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand</h3>
											<h4><span>Mức lương:</span>5000-5500 NZ</h4>
											<h4><span>Quốc gia:</span>New Zealand</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao"
											><h3>Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao</h3>
											<h4><span>Mức lương:</span>2.200 Euro</h4>
											<h4><span>Quốc gia:</span>Hungary</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới"
											><h3>Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới</h3>
											<h4><span>Mức lương:</span>1.100 USD</h4>
											<h4><span>Quốc gia:</span>Hungary</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử"
											><h3>3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử</h3>
											<h4><span>Mức lương:</span>2000USD</h4>
											<h4><span>Quốc gia:</span>Hungary</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung"
											><h3>Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung</h3>
											<h4><span>Mức lương:</span>23.800 Đài tệ</h4>
											<h4><span>Quốc gia:</span>Đài Loan</h4></a
										>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani"
											><h3>Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani</h3>
											<h4><span>Mức lương:</span>1000 USD</h4>
											<h4><span>Quốc gia:</span>Rumani</h4></a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="clear"></div>
					<div class="xemthemhome" style="margin-top: 80px;"><a href="javascript:void(0);" title="Xem thêm"></a></div>
				</div>
			</div>
		</div>
		<div class="call-mobile">
			<a href="tel:0583706666"><img src="/img/team/phone_mobile.png" alt="phone" title="phone" />0583706666</a>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: "Hồ Chí Minh",
					1: "Hà Nội",
					2: "Đà Nẵng",
					3: "Vũng Tàu",
				},
				{
					0: "Quy Nhơn",
					1: "Đà Lạt",
					2: "Hải Phòng",
					3: "Bắc Ninh",
				},
			],
			datalist: [],
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/list?id=" + data.id + "&name=" + data.name});
			}
		},
	},
	created() {},
};
</script>

<style lang="less" scoped>
.page {
	background-color: #f2f2f5;
}
.pageone-title h2 {
	margin: 0;
	font-size: 2.267vw;
	background: #fff;
	display: inline-block;
	padding-right: 1.333vw;
	font-size: 15px;
	display: block;
	text-transform: uppercase;
	font-weight: 700;
	color: #333;
}
.page ul li {
	list-style: none;
	padding: 7px 12px;
	background: #f7f7f7;
	box-shadow: 0 1px 2px 0 #999;
	border-radius: 3px;
}
.pageone-tab .nav-tabs > li {
	border-left: 1px solid #cdcdcd;
	width: 50%;
	background: #008e97;
	border-bottom: 2px solid #fff;
	float: left;
	text-align: center;
}
.pageone-tab .nav-tabs > li a {
	color: #fff;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
}
.donhang-home {
	width: 100%;
	border: 1px solid #cdcdcd;
}
.donhang-home tr td {
	border: 1px solid #cdcdcd;
	padding: 7px 5px;
	max-width: 200px;
	font-size: 11px;
	white-space: nowrap;
}
.donhang-home tr:first-child td {
	font-weight: 700;
	background: #d5dbfe !important;
}
.xemthemhome {
	font-size: 11px;
	text-align: right;
	margin-top: 10px;
	font-weight: 700;
	text-decoration: underline;
}
.xemthemhome a {
	color: red;
}
.pagetow h2 {
	background: url(/img/team/bg-titile-L.jpg) no-repeat 0;
	line-height: 42px;
	width: 100%;
	padding-left: 15px;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
}
.widget-highlight-job .widget-wrap {
	max-height: 320px;
	overflow-x: hidden;
	overflow-y: scroll;
}
.page ul {
	text-align: center;
	float: left;
	margin-left: 0;
	width: 100%;
}
.widget-highlight-job .widget-content > ul {
	column-count: 2;
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-gap: 10px;
}
.widget-content > ul > li {
	border-bottom: 1px dashed #ddd;
	padding: 5px 5px;
	padding-top: 10px;
}
.widget-content > ul > li > a > h3 {
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	color: #333;
	max-height: 42px;
	overflow: hidden;
	white-space: normal !important;
	margin-top: 0;
}
.widget-content > ul > li > a {
	color: #666;
}
.widget-content > ul > li > a > h4 {
	font-size: 10pt;
	font-weight: 500;
	display: inline-block;
	margin-top: 5px;
	margin-right: 10px;
	color: #0158b5;
}
.widget-content > ul > li > a > h4 > span {
	font-weight: 300;
	padding-right: 5px;
	color: #444;
	font-size: 11px;
}
.call-mobile {
	background: #e5332d;
	position: fixed;
	bottom: 60px;
	height: 40px;
	line-height: 40px;
	padding: 0 20px 0 0;
	border-radius: 40px;
	color: #fff;
	left: 10px;
	z-index: 6;
	width: 200px;
	text-align: center;
}
.call-mobile img {
	background-color: #ad0202;
	border-radius: 39px;
	float: left;
	width: 39px;
	height: 39px;
	margin-right: 10px;
}
.call-mobile a {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;
	white-space: nowrap;
}
.call-mobile1 {
	background: #339af2;
	position: fixed;
	bottom: 60px;
	height: 40px;
	line-height: 40px;
	padding: 0 20px 0 0;
	border-radius: 40px;
	color: #fff;
	left: 10px;
	z-index: 6;
	width: 200px;
	text-align: center;
}
.call-mobile1 img {
	background-color: #1f8eee;
	border-radius: 39px;
	float: left;
	width: 39px;
	height: 39px;
	margin-right: 10px;
}
</style>
