<template>
	<div class="home-container">
		<div class="home-scroll">
			<header class="sc-1c06ay3-0 gWiNFF">
				<div class="sc-1c06ay3-1 fDSyYI">
					<div class="sc-11qpg5t-0 gXrCuJ">
						<div class="sc-1onxl1x-0 hQoNeb">
							<div class="sc-1onxl1x-1 iphAvP">
								<a href="javascript:void(0);">NGHIÊP ĐOÀN LAO ĐỘNG</a>
							</div>
						</div>
						<div class="sc-1y7y4ai-0 ifZktZ">
							<div class="sc-8sshey-4 bXoHQx">
								<div class="sc-8sshey-6 dkCEqR"><input type="text" value="" placeholder="Tìm kiếm" autocomplete="off" class="sc-8sshey-5 fbjppU" /><span class="sc-1vo1n72-0 sc-8sshey-10 fpHzhT dfGAzM"></span></div>
							</div>
						</div>
					</div>
				</div>
			</header>
			<div class="sc-1b0gpch-1 dXYEMQ" style="height: auto !important;">
				<div class="sc-1b0gpch-2 hRxuIG">
					<div class="sc-11qpg5t-0 gXrCuJ">
						<div class="sc-q9qagu-15 jbsSbC">
							<div class="sc-11qpg5t-0 sc-z4q3vq-3 gXrCuJ hMZfLG">
								<div class="sc-z4q3vq-4 guedfL">
									<swiper class="movie_swiper" :options="swiperOption">
										<swiper-slide>
											<div>
												<div class="sc-z4q3vq-5 eQLvys">
													<div class="sc-z4q3vq-6 bgopks">
														<div style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
															<img alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw" src="/img/team/1ac35176b3d54ae482c0ec2a9486a229.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;" />
														</div>
														<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div>
													</div>
													<div class="sc-z4q3vq-7 dpDCLd">
														<a href="javascript:void(0);"><span class="title">Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-12 biTXqW"></li>
														</ul>
														<span class="price">Thỏa thuận</span>
													</div>
													<ul class="sc-z4q3vq-8 lpuxSi">
														<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
														<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
													</ul>
												</div>
											</div>
										</swiper-slide>
										<swiper-slide>
											<div>
												<div class="sc-z4q3vq-5 eQLvys">
													<div class="sc-z4q3vq-6 bgopks">
														<div style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
															<img alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw" src="/img/team/1ac35176b3d54ae482c0ec2a9486a229.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;" />
														</div>
														<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div>
													</div>
													<div class="sc-z4q3vq-7 dpDCLd">
														<a href="javascript:void(0);"><span class="title">Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-12 biTXqW"></li>
														</ul>
														<span class="price">Thỏa thuận</span>
													</div>
													<ul class="sc-z4q3vq-8 lpuxSi">
														<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
														<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
													</ul>
												</div>
											</div>
										</swiper-slide>
										<swiper-slide>
											<div>
												<div class="sc-z4q3vq-5 eQLvys">
													<div class="sc-z4q3vq-6 bgopks">
														<div style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
															<img alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw" src="/img/team/1ac35176b3d54ae482c0ec2a9486a229.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;" />
														</div>
														<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div>
													</div>
													<div class="sc-z4q3vq-7 dpDCLd">
														<a href="javascript:void(0);"><span class="title">Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-12 biTXqW"></li>
														</ul>
														<span class="price">Thỏa thuận</span>
													</div>
													<ul class="sc-z4q3vq-8 lpuxSi">
														<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
														<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
													</ul>
												</div>
											</div>
										</swiper-slide>
										<swiper-slide>
											<div>
												<div class="sc-z4q3vq-5 eQLvys">
													<div class="sc-z4q3vq-6 bgopks">
														<div style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
															<img alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw" src="/img/team/1ac35176b3d54ae482c0ec2a9486a229.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;" />
														</div>
														<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div>
													</div>
													<div class="sc-z4q3vq-7 dpDCLd">
														<a href="javascript:void(0);"><span class="title">Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-12 biTXqW"></li>
														</ul>
														<span class="price">Thỏa thuận</span>
													</div>
													<ul class="sc-z4q3vq-8 lpuxSi">
														<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
														<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
													</ul>
												</div>
											</div>
										</swiper-slide>
										<swiper-slide>
											<div>
												<div class="sc-z4q3vq-5 eQLvys">
													<div class="sc-z4q3vq-6 bgopks">
														<div style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
															<img alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw" src="/img/team/1ac35176b3d54ae482c0ec2a9486a229.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;" />
														</div>
														<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div>
													</div>
													<div class="sc-z4q3vq-7 dpDCLd">
														<a href="javascript:void(0);"><span class="title">Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-12 biTXqW"></li>
														</ul>
														<span class="price">Thỏa thuận</span>
													</div>
													<ul class="sc-z4q3vq-8 lpuxSi">
														<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
														<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
													</ul>
												</div>
											</div>
										</swiper-slide>
									</swiper>
								</div>
							</div>
						</div>
						<div class="sc-f5e146-0 cOcyJJ"></div>
						<div class="sc-q9qagu-4 enWxAW">
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<div style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
											<img alt="TUYỂN NAM PHỤ BẾP CHO BẾP TRƯỞNG TẠI BẾP ĂN TRƯỜNG HỌC QUẬN ĐỐNG ĐA." fill="true" sizes="100vw" src="/img/team/8e9ba624901c42d285e8cc1809f9775e.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;" />
										</div>
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>TUYỂN NAM PHỤ BẾP CHO BẾP TRƯỞNG TẠI BẾP ĂN TRƯỜNG HỌC QUẬN ĐỐNG ĐA.</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Từ 5.500.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Đống Đa, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<div style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
											<img alt="Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van" fill="true" sizes="100vw" src="/img/team/5d4ec8c3e79b4bb481a203bd4827c192.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;" />
										</div>
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">9.500.000 - 12.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/03435067b0174cf1be285f52d8522b2d.jpg" alt="TUYỂN 01 NAM LÁI XE NÂNG – 01 CÁN BỘ QUẢN LÝ SẢN XUẤT" src="/img/team/03435067b0174cf1be285f52d8522b2d.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>TUYỂN 01 NAM LÁI XE NÂNG – 01 CÁN BỘ QUẢN LÝ SẢN XUẤT</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">8.000.000 - 12.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Huyện Thường Tín, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/26210c36eb2248b0a1cd08c63b46d942.jpg" alt="TUYỂN 03 LAO ĐỘNG PHỔ THÔNG + NHÂN VIÊN KỸ THUẬT" src="/img/team/26210c36eb2248b0a1cd08c63b46d942.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>TUYỂN 03 LAO ĐỘNG PHỔ THÔNG + NHÂN VIÊN KỸ THUẬT</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">8.000.000 - 12.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Huyện Thanh Trì, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/09133f8eb067428b982f152a66e40521.jpg" alt="Tuyển nhân viên pha chế, chạy bàn, đầu bếp Âu cho quán cafe" src="/img/team/09133f8eb067428b982f152a66e40521.jpg" class="thumb-l lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Tuyển nhân viên pha chế, chạy bàn, đầu bếp Âu cho quán cafe</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Ba Đình, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/category-2.png" alt="Tuyển nhân viên nữ tẩm quất giác hơi lành mạnh, từ 18-35 tuổi" src="/img/team/category-2.png" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Tuyển nhân viên nữ tẩm quất giác hơi lành mạnh, từ 18-35 tuổi</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Tây Hồ, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/66774a8b89c24e18857dd948c434770d.jpg" alt="Cần tuyển nhân sự bếp, bàn, tạp vụ" src="/img/team/66774a8b89c24e18857dd948c434770d.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Cần tuyển nhân sự bếp, bàn, tạp vụ</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Từ 34.000 đ</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/category-2.png" alt="Cần tuyển 2 lái xe 7 chỗ chạy du lịch và đưa đón văn phòng " src="/img/team/category-2.png" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Cần tuyển 2 lái xe 7 chỗ chạy du lịch và đưa đón văn phòng </span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/5af10832bdd24c53a77dc0e8f3b1be05.jpg" alt="TUYỂN GẤP LÁI XE ĐI LÀM NGAY" src="/img/team/5af10832bdd24c53a77dc0e8f3b1be05.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>TUYỂN GẤP LÁI XE ĐI LÀM NGAY</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Từ 8.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Tây Hồ, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/1ac35176b3d54ae482c0ec2a9486a229.jpg" alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" src="/img/team/1ac35176b3d54ae482c0ec2a9486a229.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<section>
								<div class="sc-f5e146-0 fpAWLv"></div>
								<div class="sc-q9qagu-15 jbsSbC">
									<div class="sc-11qpg5t-0 sc-z4q3vq-3 gXrCuJ hMZfLG">
										<div class="sc-z4q3vq-4 guedfL"></div>
									</div>
								</div>
								<div class="sc-f5e146-0 fpAWLv"></div>
							</section>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/8fe2a51ea1244e768d593027a2988840.jpg" alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng." src="/img/team/8fe2a51ea1244e768d593027a2988840.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng.</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Từ 10.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Nam Từ Liêm, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/category-2.png" alt="Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm" src="/img/team/category-2.png" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">5.000.000 - 6.500.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/8e0c3c899f7c44f7853d250747ad0ec9.jpg" alt="TUYỂN BẢO VỆ ĐI LÀM NGAY, CÓ CHỖ Ở : " src="/img/team/8e0c3c899f7c44f7853d250747ad0ec9.jpg" class="thumb-l lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span><span class="sc-1vo1n72-4 fjImPV">3</span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>TUYỂN BẢO VỆ ĐI LÀM NGAY, CÓ CHỖ Ở : </span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">6.840.000 - 10.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/2329291cd6aa4981a48a54037fd95594.jpg" alt=" tuyển gấp nhân viên lái xe và phụ xe chạy tuyến cố định từ sân bay về" src="/img/team/2329291cd6aa4981a48a54037fd95594.jpg" class="thumb-l lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span> tuyển gấp nhân viên lái xe và phụ xe chạy tuyến cố định từ sân bay về</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">10.000.000 - 12.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/b5ff8195a1ac4add93388a5d6001ab37.jpg" alt="Tuyển kỹ thuật máy tính máy in lương cao. Chưa biết nghề được đào tạo." src="/img/team/b5ff8195a1ac4add93388a5d6001ab37.jpg" class="thumb-l lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Tuyển kỹ thuật máy tính máy in lương cao. Chưa biết nghề được đào tạo.</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/5e608f5adece454ebfe371874e129589.jpg" alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI" src="/img/team/5e608f5adece454ebfe371874e129589.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>CẦN TUYỂN: 10 NAM LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/cd73b959061d4e89821d3b90e889982a.jpg" alt="CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM" src="/img/team/cd73b959061d4e89821d3b90e889982a.jpg" class="thumb-l ls-is-cached lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/e7dabb0252214661b987c004d4fbdf8b.jpg" alt="Long Biên- CTY ONG VÀNG TUYỂN GIAO HÀNG THEO XE Ô TÔ" src="/img/team/e7dabb0252214661b987c004d4fbdf8b.jpg" class="thumb-l lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Long Biên- CTY ONG VÀNG TUYỂN GIAO HÀNG THEO XE Ô TÔ</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Từ 10.000.000 đ/tháng</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Long Biên, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/d2b168e9a2a9480098f0fad25fde8789.jpg" alt="Tuyển nhân viên massage tẩm quất, làm tại 68 Cầu Bươu, Thanh Trì, HN" src="/img/team/d2b168e9a2a9480098f0fad25fde8789.jpg" class="thumb-l lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Tuyển nhân viên massage tẩm quất, làm tại 68 Cầu Bươu, Thanh Trì, HN</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Huyện Thanh Trì, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
							<div class="sc-q9qagu-5 kNNcTy">
								<div class="sc-q9qagu-3 bsQfQa">
									<div class="sc-q9qagu-7 kqqkWq">
										<img data-src="/img/team/29c38ddca68f483b844027998f1bf7a7.jpg" alt="Tuyển gấp: Thợ xén, Thợ máy chần, Thợ máy bằng, Quản lý sản xuất..." src="/img/team/29c38ddca68f483b844027998f1bf7a7.jpg" class="thumb-l lazyloaded" />
										<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div>
										<span class="sc-1p2nuuk-1 csPPf"></span>
									</div>
									<div class="sc-q9qagu-14 eOzaio">
										<div class="sc-q9qagu-8 dGTvSk">
											<a href="javascript:void(0);" class="title"><span>Tuyển gấp: Thợ xén, Thợ máy chần, Thợ máy bằng, Quản lý sản xuất...</span></a>
											<ul class="sc-q9qagu-9 bIsYGD"></ul>
											<span class="price">Thỏa thuận</span>
										</div>
										<ul class="sc-q9qagu-9 bIsYGD">
											<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
											<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="sc-1b0gpch-3 ifmEXn" style="height: auto !important; display: none;">
					<div>
						<div class="sc-3bovxq-0 jrPEep">
							<div>Ngành nghề</div>
							<ul class="sc-3bovxq-1 cHIamR">
								<li><a href="javascript:void(0);">Bán hàng</a></li>
								<li><a href="javascript:void(0);">Người giúp việc, tạp vụ</a></li>
								<li><a href="javascript:void(0);">Công nhân</a></li>
								<li><a href="javascript:void(0);">Lái xe, phụ xe</a></li>
								<li><a href="javascript:void(0);">Kế toán, thu ngân</a></li>
								<li><a href="javascript:void(0);">An ninh, bảo vệ</a></li>
							</ul>
							<button class="sc-3bovxq-2 eAIMNB"><span>Xem thêm</span><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT fKCVLE"></span></button>
						</div>
						<div class="sc-3bovxq-0 jrPEep">
							<div>Quận/Huyện</div>
							<ul class="sc-3bovxq-1 cHIamR">
								<li><a href="javascript:void(0);">Quận Ba Đình</a></li>
								<li><a href="javascript:void(0);">Quận Bắc Từ Liêm</a></li>
								<li><a href="javascript:void(0);">Quận Cầu Giấy</a></li>
								<li><a href="javascript:void(0);">Quận Đống Đa</a></li>
								<li><a href="javascript:void(0);">Quận Hà Đông</a></li>
								<li><a href="javascript:void(0);">Quận Hai Bà Trưng</a></li>
							</ul>
							<button class="sc-3bovxq-2 eAIMNB"><span>Xem thêm</span><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT fKCVLE"></span></button>
						</div>
					</div>
					<div class="sc-1387a10-0 cNFLhZ">
						<div class="sc-ri4pcv-0 bfKsqJ">
							<ins data-ad-client="ca-pub-3000426983537206" data-ad-slot="7446036210" data-ad-format="auto" data-full-width-responsive="true" data-adsbygoogle-status="done" data-ad-status="filled" class="adsbygoogle" style="display: block; transition: none 0s ease 0s; outline: none; border: none; padding: 0px; border-spacing: 0px; margin-left: -16px; width: 414px; z-index: 30; height: 345px;"
								><div id="aswift_1_host" tabindex="0" title="Advertisement" aria-label="Advertisement" style="border: none; height: 345px; width: 414px; margin: 0px; padding: 0px; position: relative; visibility: visible; background-color: transparent; display: inline-block; overflow: visible;">
									<iframe
										data-v-9c516a62=""
										id="aswift_1"
										name="aswift_1"
										browsingtopics="true"
										sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
										width="414"
										height="345"
										frameborder="0"
										marginwidth="0"
										marginheight="0"
										vspace="0"
										hspace="0"
										allowtransparency="true"
										scrolling="no"
										allow="attribution-reporting"
										src=""
										data-google-container-id="a!2"
										data-google-query-id="CK7vrdiAgoEDFQHEFgUdFzgNRQ"
										data-load-complete="true"
										style="left: 0px; position: absolute; top: 0px; border: 0px; width: 414px; height: 345px;"
									></iframe></div
							></ins>
						</div>
					</div>
				</div>
			</div>
			<div class="banner" style="display: none;">
				<div class="swiper-container banner_swiper swiper-container-coverflow swiper-container-3d swiper-container-initialized swiper-container-horizontal" style="cursor: grab;">
					<div class="swiper-wrapper" style="transition-duration: 800ms;">
						<div class="swiper-slide">
							<div class="banner_img van-image van-image--round">
								<div class="van-image__error">
									<i class="van-icon van-icon-photo-fail van-image__error-icon"><!----></i>
								</div>
							</div>
						</div>
						<div class="swiper-slide">
							<div class="banner_img van-image van-image--round">
								<div class="van-image__error">
									<i class="van-icon van-icon-photo-fail van-image__error-icon"><!----></i>
								</div>
							</div>
						</div>
					</div>
					<span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
				</div>
			</div>
			<div class="notice-bar" style="display: none;">
				<div role="alert" class="notice-swipe van-notice-bar" style="color: rgb(249, 5, 229); background: rgb(255, 255, 255);">
					<i class="van-icon van-icon-bullhorn-o van-notice-bar__left-icon"><!----></i>
					<div role="marquee" class="van-notice-bar__wrap">
						<div class="van-notice-bar__content" style="transition-duration: 0s;">
							Giới thiệu về chức năng và trách nhiệm của Nghiệp Đoàn Lao Động. Theo quy trình đi XKLĐ, khi xuất cảnh sang Quốc gia khác, người lao động sẽ về nghiệp đoàn đăng ký hồ sơ. Tại đây, người lao động sẽ được nghiệp đoàn đào tạo các kỹ năng và cung cấp những kiến thức cơ bản giúp người lao động sớm hòa nhập với cuộc sống và công việc tại nước ngoài. Ngoài ra, người lao động sẽ được nhận trợ cấp trong thời gian đào tạo này. Trong suốt quá trình làm việc 1 năm, 3 năm hay 5 năm thì nghiệp
							đoàn có trách nhiệm quản lý người lao động. Trước hết là đảm bảo cuộc sống, công việc của người lao động theo đúng hợp đồng, sau là đến quản lý tránh trường hợp lao động bỏ trốn, phá vỡ hợp đồng. Khi các Quốc gia muốn tuyển dụng lao động nước ngoài sẽ gửi thông tin đến nghiệp đoàn địa phương và mọi quá trình tuyển dụng sẽ đều thông qua nghiệp đoàn. Nghiệp đoàn và các doanh nghiệp sẽ trao đổi các tiêu chí tuyển chọn lao động để thống nhất đưa ra yêu cầu tuyển dụng và gửi đến các
							doanh nghiệp xuất khẩu lao động tại Việt Nam. Như vậy, lao động khi sang làm việc tại Nước ngoài sẽ đều phải thông qua nghiệp đoàn.
						</div>
					</div>
				</div>
				<div class="linear-gradient"></div>
			</div>
			<div class="hot-game" style="display: none;">
				<div class="hot-title-div">
					<div><span>Nhiệm vụ tình một đêm</span></div>
					<div>
						<span>Xem thêm</span><i class="van-icon van-icon-arrow" style="color: rgb(151, 151, 153);"><!----></i>
					</div>
				</div>
				<div class="hot-items-div"><div class="van-grid"></div></div>
			</div>
			<div class="van-pull-refresh">
				<div class="van-pull-refresh__track" style="transition-duration: 0ms;">
					<div class="van-pull-refresh__head"></div>
					<div class="hot-recommend">
						<div class="hot-title-div" style="display: none;">
							<div><span>Phổ biến nhất</span></div>
							<div>
								<span>Xem thêm</span><i class="van-icon van-icon-arrow" style="color: rgb(151, 151, 153);"><!----></i>
							</div>
						</div>
						<div class="movie_list_0" style="display: none;">
							<div class="swiper-container movie_swiper swiper-container-initialized swiper-container-horizontal">
								<div class="swiper-wrapper" style="transition-duration: 0ms;"></div>
								<span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
							</div>
						</div>
						<div class="hot-title-div" style="display: none;">
							<div><span>công việc mới nhất</span></div>
							<div>
								<span>Xem thêm</span><i class="van-icon van-icon-arrow" style="color: rgb(151, 151, 153); font-size: 25px;"><!----></i>
							</div>
						</div>
						<div class="movie_list_1 latest_jobs" style="display: none;">
							<div class=" latest_item">
								<div class="item_name_line">
									<a class="item_name substring font15">测试招聘</a>
									<div class="clear"></div>
									<div class="item_wage font13">1万-3万/mặt trăng</div>
								</div>
								<div class="item_some_line">
									<div class="item_some substring font12 sssb">|100-499人 | 国企 | 劳务派遣 | 出国留学 | 出入境</div>
									<div class="item_date font12">2023-08-16 03:21:08</div>
								</div>
								<div class="item_tag">
									<div class="tag_cell">加</div>
									<div class="tag_cell">班</div>
									<div class="tag_cell">费</div>
									<div class="tag_cell">,</div>
									<div class="tag_cell">包</div>
									<div class="tag_cell">食</div>
									<div class="tag_cell">宿</div>
									<div class="clear"></div>
								</div>
								<div class="split_line"></div>
								<div class="item_company_line">
									<a class="company_name substring font12">杨老师工作室</a>
									<div class="company_auth"></div>
									<div class="clear"></div>
								</div>
								<div class="split_line1"></div>
							</div>
							<div class="hot-recommend-more">Xem thêm</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="height:100px"></div>
		<div class="call-mobile">
			<a href="tel:0583706666"><img src="/img/team/phone_mobile.png" alt="phone" title="phone" />0583706666</a>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			notice: "Nhận......",
			noticeUser: {},
			popupUser: {},
			popup: localStorage.getItem("popup") == "true" ? true : false,
			banners: [{}],
			basicData: [],
			gameitem: [{}, {}, {}, {}],
			gameitem2: [{}, {}, {}, {}],
			movielist_0: [{}, {}, {}, {}],
			movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
			isLoading: false,
			swiperOption: {
				slidesPerView: 3.5,
				spaceBetween: 5,
				slidesPerGroup: 1,
				breakpoints: {
					320: {
						slidesPerView: 2.3,
						spaceBetween: 5,
					},
					480: {
						slidesPerView: 2.4,
						spaceBetween: 10,
					},
					640: {
						slidesPerView: 3.5,
						spaceBetween: 10,
					},
				},
			},
		};
	},
	methods: {
		gotoMenu(router) {
			this.$router.replace(router);
		},
		toLottery(key, id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/Lottery?key=" + key + "&id=" + id});
			}
		},
		toPlayVideo(id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/PlayVideo?id=" + id});
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.getBasicConfig();
				this.isLoading = false;
				this.$toast("Làm mới thành công");
			}, 500);
		},
		getBasicConfig() {
			this.$http({
				method: "get",
				url: "sys_config",
			}).then(res => {
				this.basicData = res.data;
				this.getNotice(this.basicData);
				this.getBanner(this.basicData);
				this.getGameItem();
				this.getGameItem2();
				this.getMovieList_0(this.basicData);
				this.getMovieList_1(this.basicData);
			});
		},
		getNotice(data) {
			this.notice = data.notice;
		},
		getNoticeList() {
			this.$http({
				method: "get",
				url: "sys_get_notice_list",
			}).then(res0 => {
				this.noticeUser = res0.data;
				this.$http({
					method: "get",
					url: "user_info",
				}).then(res => {
					if (res.code === 200) {
						if (!localStorage.getItem("popup")) {
							this.popup = this.noticeUser.some(function(item) {
								return item.user === res.data.username;
							});
						}
						/*localStorage.setItem('popup', 
              this.noticeUser.some(function(item) {
                return item.user === res.data.username;
              })
            );*/
						this.popupUser = this.noticeUser.filter(function(item) {
							if (item.user === res.data.username) {
								return item;
							}
						});
					}
				});
			});
		},
		closePopup() {
			localStorage.setItem("popup", false);
			this.popup = false;
		},
		getGameItem() {
			this.$http({
				method: "get",
				url: "lottery_hot",
			}).then(res => {
				this.gameitem = res.data;
			});
		},
		getGameItem2() {
			this.$http({
				method: "get",
				url: "lottery_hot2",
			}).then(res => {
				this.gameitem2 = res.data;
			});
		},
		getMovieList_0(data) {
			this.movielist_0 = data.movielist_0;
		},
		getMovieList_1(data) {
			this.movielist_1 = data.movielist_1;
		},
		getBanner(data) {
			this.banners = data.banners;
		},
	},
	mounted() {},
	created() {
		this.getBasicConfig();
	},
};
</script>

<style lang="less" scoped>
.call-mobile {
	background: #e5332d;
	position: fixed;
	bottom: 60px;
	height: 40px;
	line-height: 40px;
	padding: 0 20px 0 0;
	border-radius: 40px;
	color: #fff;
	left: 10px;
	z-index: 6;
	width: 200px;
	text-align: center;
}
.call-mobile img {
	background-color: #ad0202;
	border-radius: 39px;
	float: left;
	width: 39px;
	height: 39px;
	margin-right: 10px;
}
.call-mobile a {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;
	white-space: nowrap;
}
.gWiNFF {
	background: #fff;
	border-bottom: 1px solid #ecedef;
}
.fDSyYI {
	display: block;
}
.gXrCuJ {
	width: 100%;
	margin: 0 auto;
	position: relative;
	padding: 0;
}
.hQoNeb {
	display: flex;
	width: 100%;
	align-items: center;
	height: 50px;
}
.iphAvP {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: start;
	height: 100%;
}
.iphAvP a,
.iphAvP img {
	width: 100%;
	height: 22px;
}
.iphAvP a {
	font-size: 18px;
	font-weight: bold;
	color: #f57301;
	margin-left: 5px;
	letter-spacing: -2px;
}
.ifZktZ {
	position: absolute;
	top: 0;
	right: 0;
	width: 160px;
	display: flex;
	justify-content: center;
}
.bXoHQx {
	display: flex;
	justify-content: center;
	left: 0;
	top: 45px;
	width: 100%;
	height: auto;
	z-index: 100;
	background: #fff;
}
.dkCEqR {
	padding: 0 6px;
	background: #fff;
	height: 50px;
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 101;
	max-width: 460px;
}
.fbjppU {
	width: 100%;
	outline: none;
	background-color: #fff;
	border: 1px solid #ecedef;
	border-radius: 24px;
	color: #333;
	font-weight: 400;
	z-index: 100;
}
.fbjppU {
	height: 36px;
	padding-left: 8px;
	padding-right: 60px;
	font-size: 16px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.fpHzhT {
	background-color: transparent;
	display: inline-block;
	background-image: url(/img/team/common.png);
}
.dfGAzM {
	cursor: pointer;
	position: absolute;
	z-index: 100;
	right: 14px;
	width: 20px;
	height: 20px;
	background-position: -175px -20px;
}
.hMZfLG {
	padding: 16px 5px;
	background: linear-gradient(180deg, #ffe2c6, rgba(255, 241, 235, 0.4) 53.12%, #fff 88.98%, #fff);
}
.cOcyJJ {
	height: 12px;
	background-color: #f5f7f9;
}
.enWxAW {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	background: #fff;
}
.kNNcTy {
	background: #fff;
	border-bottom: 1px solid #ecedef;
	border-right: 1px solid #ecedef;
	border-left: 1px solid #ecedef;
	border-top: 1px solid #ecedef;
	width: 100%;
	position: relative;
	padding: 16px 8px;
}
.bsQfQa {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: row;
}
.kqqkWq {
	width: 144px;
	height: 108px;
}
.kqqkWq {
	position: relative;
	border-radius: 2px;
	background-color: #f1f1f1;
}
.csPPf {
	z-index: 1;
	position: absolute;
	top: 0;
	right: 0;
	width: 36px;
	height: 36px;
	cursor: pointer;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-image: url(/img/team/common.svg);
	background-position: 6px 6px;
}
.gCoOOb {
	position: absolute;
	padding: 0 8px;
	border-radius: 4px;
	background: #ff641e;
	top: 4px;
	left: 4px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.thumb-l {
	border-radius: 2px;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.gCoOOb span {
	font-size: 8px;
	font-weight: 700;
	color: #fff;
}
.eOzaio {
	max-width: calc(100% - 144px);
	padding-left: 8px;
}
.eOzaio {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}
.dGTvSk {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2px;
}
.dGTvSk span {
	margin: 0;
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	max-height: 48px;
	margin-bottom: 4px;
	width: 100%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	color: #333;
}
.eQLvys {
	width: 100%;
	height: 249px;
	padding: 8px;
}
.eQLvys {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	text-align: left;
	position: relative;
	background-color: #fff;
}
.bgopks {
	width: 100%;
	display: block;
	border-radius: 2px;
	position: relative;
	background-color: #f1f1f1;
	padding-top: 75%;
}
.dpDCLd {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 4px;
}
.dpDCLd .title {
	font-size: 15px;
	margin-bottom: 0;
	display: block;
	font-weight: 400;
	line-height: 24px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	color: #333;
	min-height: 48px;
}
.dpDCLd .title {
	min-height: 40px;
	font-size: 14px;
	line-height: 20px;
}
.lpuxSi {
	display: flex;
	align-items: center;
	color: #717375;
	font-size: 13px;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	text-overflow: ellipsis;
	margin-top: 3px;
	min-height: 22px;
}
.biTXqW {
	margin-bottom: 0;
	min-height: 20px;
	display: inline-block;
	color: #717375;
	font-size: 14px;
	font-size: 0.875rem;
}
.dpDCLd .price {
	margin: 5px 0 0;
	color: #d14200;
	min-height: 24px;
	min-width: 80px;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 13px;
}
.lpuxSi {
	display: flex;
	align-items: center;
	color: #717375;
	font-size: 9px;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	text-overflow: ellipsis;
	margin-top: 3px;
	min-height: 22px;
}
.joVhly {
	display: flex;
	align-items: flex-end;
	margin-right: 5px;
}
.fpHzhT {
	background-color: transparent;
	display: inline-block;
	background-image: url(/img/team/common.png);
}
.joVhly span {
	display: inline-block;
	line-height: 16px;
}
.gVZxfc {
	width: 16px;
	height: 16px;
	background-position: -20px -46px;
	margin-right: 2px;
}
.cZhfaC {
	position: absolute;
	padding: 0 8px;
	border-radius: 4px;
	background: #bf1d28;
	top: 4px;
	left: 4px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.cZhfaC span {
	font-weight: 700;
	font-size: 8px;
	color: #fff;
}
.bGiBvb {
	width: 16px;
	height: 16px;
	background-position: -45px -46px;
	margin-right: 2px;
}
.csPPf {
	z-index: 1;
	position: absolute;
	top: 0;
	right: 0;
	width: 36px;
	height: 36px;
	cursor: pointer;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-image: url(/img/team/common.svg);
	background-position: 6px 6px;
}
.fjImPV {
	position: absolute;
	width: 24px;
	height: 20px;
	color: #fff;
	font-size: 12px;
	text-align: center;
	background-image: url(/img/team/common.svg);
	background-position: 0 -242px;
}
.fjImPV {
	bottom: 6px;
	right: 6px;
}
.jrxHxK {
	position: absolute;
	padding: 0 8px;
	border-radius: 4px;
	background: #ffc400;
	top: 4px;
	left: 4px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.jrxHxK span {
	font-size: 8px;
	font-weight: 700;
	color: #fff;
}
.ijSNSa {
	margin-right: 5px;
}
.geNyUe {
	display: flex;
	align-items: flex-end;
}
.geNyUe span {
	display: inline-block;
	line-height: 16px;
}
.bIsYGD {
	display: flex;
	align-items: center;
	color: #717375;
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	list-style: none;
	text-overflow: ellipsis;
}
.dGTvSk .price {
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	color: #d14200;
	min-height: 24px;
	min-width: 80px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
